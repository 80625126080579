import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  Stack,
} from '@mui/material';
import Swal from 'sweetalert2';
import { Helpers } from '../../services/Helpers';
import { CustomerService } from './Service';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function PersonalDetails({ userIdProp, personalDetails, onSubmitPersonal, tabNextChange }) {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });
  const validationRules = {
    required: 'This field is required',
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (personalDetails) {
      setValue('firstName', personalDetails.firstName);
      setValue('middleName', personalDetails.middleName);
      setValue('lastName', personalDetails.lastName);
      setValue('email', personalDetails.email);
      setValue('dob', personalDetails.dob);
      setValue('gender', personalDetails.gender);
      setValue('mobileNumber', personalDetails.mobileNumber);
    }
  }, [personalDetails]);

  const handleNext = () => {
    tabNextChange(1)
  }

  const [isSubmitting,setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    setIsSubmitting(true)
    console.log("submitted customer data:",data)
    // const name = `${data.firstName} ${data.lastName}`;
    const personalData = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      mobileNumber: data.mobileNumber,
      gender: data.gender,
      email: data.email,
      dob: data.dob
    }

    const createRow = async () => {
      let createRowRes = "";
      let msg = "";
      if (userIdProp > 0) {
        createRowRes = await CustomerService.updateCustomer(userIdProp, personalData);
        personalData.id = userIdProp;
        msg = "Update Successfully";
      } else {
        if (personalDetails.id > 0) {
          createRowRes = await CustomerService.updateCustomer(personalDetails.id, personalData);
          personalData.id = personalDetails.id;
          msg = "Update Successfully";
        } else {
          // personalData.password = data.password;
          createRowRes = await CustomerService.addCustomer(data);
          if (createRowRes.success) {
            personalData.id = createRowRes.data.id;
            msg = "User Registerd Successfully";
          } else {
            Swal.fire(createRowRes.message, '', 'error')
          }
        }
      }

      if (createRowRes.success) {
        onSubmitPersonal(personalData)
        navigate(`/admin/customers`);
        Swal.fire(msg, '', 'success')
        setIsSubmitting(true)
      } else {
        Swal.fire(createRowRes.message, '', 'error');
        setIsSubmitting(true)
      }
    }
    createRow();
  };


  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
          <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
            <Grid container spacing={2} sx={{ paddingTop: '15px' }}>
            <Grid item lg={4} xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  rules={validationRules}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter First Name"
                      label="Enter First Name"
                      size="small"
                      fullWidth
                      error={!!errors.firstName}
                      // helperText={errors.lastName && errors.lastName.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Controller
                  name="middleName"
                  control={control}
                  defaultValue=""
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Middle Name"
                      label="Enter Middle Name"
                      size="small"
                      fullWidth
                      error={!!errors.middleName}
                      // helperText={errors.middleName && errors.middleName.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Last Name"
                      label="Enter Last Name"
                      size="small"
                      fullWidth
                      error={!!errors.lastName}
                      // helperText={errors.lastName && errors.lastName.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={8} xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:'Email is Required',
                    pattern:{
                      value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      message:"Email is not Valid"
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Email"
                      label="Enter Email"
                      size="small"
                      fullWidth
                      error={!!errors.email}
                      // helperText={errors.email && errors.email.message}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <Controller
                  name="mobileNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "required",
                    pattern:{
                        value:/[1-9]{1}[0-9]{9}/,
                        message:"only numeric : not starting with 0"
                    },
                    minLength:{
                        value:10,
                        message:"contact should be of 10 digit"
                    },
                    maxLength:{
                        value:10,
                        message:"contact should be of 10 digit"
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Mobile Number"
                      label="Enter Mobile Number"
                      size="small"
                      type='number'
                      fullWidth
                      error={!!errors.mobileNumber}
                      // helperText={errors.mobileNumber && errors.mobileNumber.message}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Controller
                        name='gender'
                        control={control}
                        rules={{required:'select gender'}}
                        render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                              <InputLabel id="">Select Gender</InputLabel>
                                <Select
                                    {...field}
                                    label="Select Gender"
                                    // sx={{height:'2rem'}}
                                    error={!!error}
                                    // helperText={error && error?.message}
                                >
                                  <MenuItem value="Male">Male</MenuItem>
                                  <MenuItem value="Female">Female</MenuItem>
                                  <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        )}  
                    />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Controller
                  name="dob"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return true; // Allow empty value if not required
                      return (
                        dayjs(value).isBefore(dayjs()) ||
                        `Date of Birth must be before ${dayjs().format('DD-MM-YYYY')}`
                      );
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Enter Date of Birth"
                        inputFormat="DD-MM-YYYY"
                        value={value ? dayjs(value) : null}
                        onChange={(date) => onChange(date ? dayjs(date).format('YYYY-MM-DD') : null)}
                        maxDate={dayjs()}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            fullWidth: true,
                            required: false,
                            size:'small'
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              
                {/* <Grid item lg={4} xs={12}>
                  <Controller
                        name='pincode'
                        control={control}
                        rules={{
                            required: "required",
                            pattern:{
                                value:/[1-9]{1}[0-9]{5}/,
                                message:"only numeric : not starting with 0"
                            },
                            minLength:{
                                value:6,
                                message:"contact should be of 6 digit"
                            },
                            maxLength:{
                                value:6,
                                message:"contact should be of 6 digit"
                                    }
                        }}
                        render={({field,fieldState:{error}})=>(
                            <TextField
                                {...field}
                                fullWidth
                                type='number'
                                size='small'
                                label='Enter Pincode'
                                error={!!error}
                                // helperText={error && error?.message}
                            /> 
                        )}                       
                    />
                </Grid> */}
              {/* <Grid item lg={8} xs={12}>
              <Controller
                    name='address'
                    control={control}
                    rules={{ required:'Enter Address'}}
                    render={({field,fieldState:{error}})=>(
                        <TextField
                            {...field}
                            fullWidth
                            size='small'
                            label='Enter Address'
                            InputProps={{
                                // style:{
                                //     height:'2rem'
                                // },
                            // inputProps:{
                            //     style:{textTransform:'uppercase'},}
                            }}
                            error={!!error}
                            // helperText={error && error ?.message}
                        /> 
                    )}                   
                /> 
              </Grid> */}
              <Grid item lg={12} xs={12}>
                <Box textAlign={'center'}>
                  <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                    Register
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  {/* <Button type="button" onClick={handleNext} variant="contained" color="primary">
                    Next
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={7} xs={12}>

          </Grid>
        </Grid>
      </form>
    </Box >
  );
}
