import { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, FormControl, InputLabel, Select, Tooltip, IconButton, TableCell, Switch } from '@mui/material';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { CustomerService } from './Service';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../components/Layout/Layout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ViewProfile from './ViewProfile';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';


const style = {
  position: 'absolute',
  top: (isMobile) ? '80%' : '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: (isMobile) ? "95%" : "25%",
  bgcolor: 'background.paper',
  border: '2px solid #ddd',
  boxShadow: 24,
  p: 2,
};


export default function CustomerList() {
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
    mode: "onChange"
  });
  /************************  Table Data State Manage **************************** */
  const [allRowCount, setAllRowCount] = useState(0);
  const [list, setList] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [controller, setController] = useState({
    page: 0,
    pageSize: 5,
    filters: {
      searchKey: "",
      status: 1,
    }
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true)
        const listResponse = await CustomerService.sortedCustomerList({
          page: controller.page,
          limit: controller.pageSize,
          filters: {
            searchKey: controller.filters.searchKey,   // Use the title filter from the controller
            status: controller.filters.status,
          }
        });
        console.log("customer list Response:",listResponse)
        setValue('status', controller.filters.status)
        if (listResponse.success) {
          setList(listResponse.data.customers);
          setAllRowCount(listResponse.data.totalItems);
        } 
        // else {
        //   Swal.fire('Request failed','','error')
        // }
      } catch (error) {
        Swal.fire(error.message,'','error')
      }
      setLoading(false)
    };
    getData();
  }, [controller, deleteRowData]);

  const [customerProfile,setCustomerProfile] = useState(null);
  
  /************************  Table Column Manage **************************** */
  const columns = [
    { field: "id", headerAlign: 'center', headerName: "User ID", width: 100, editable: false },
    {
      field: "name", headerAlign: 'center', headerName: "Name", flex: 1, editable: false, valueGetter: (params) => {
        return `${params.row.profile.firstName} ${params.row.profile.middleName} ${params.row.profile.lastName}`;
      }
    },
    { field: "gender", headerAlign: 'center', headerName: "Gender", flex: 1, editable: false,
      valueGetter: (params) => {
        return `${params.row.profile.gender}`;
      }
    },
    { field: "email", headerAlign: 'center', headerName: "EMAIL", width: 250, editable: false,
      valueGetter: (params) => {
        return `${params.row.profile.email}`;
      }
    },
    { field: "mobileNumber", headerAlign: 'center', headerName: "Mobile Number", flex: 1, editable: false },
    { field: "status", headerAlign: 'center', headerName: "Status", flex: 1, editable: false,
      renderCell:(params)=>(
        <>{params.row.isVerified ? 'verified' : 'non-verified'}</>
      )
    },
    {field: 'action', headerAlign: 'center', headerName: 'Action', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton color='warning' onClick={()=>setCustomerProfile(params.row)}><RemoveRedEyeIcon/></IconButton>
          </Stack>
        );
      },
    },
  ];

  /************************  Remove table row **************************** */
  const removeTableRow = async (id) => {
    // if (!id) return;
    // const delP = await Brand.deleteBrand(id);
    // if (delP.success) {
    //     setDeleteRow(id);
    //     Swal.fire('Deleted!', '', 'success')
    // } else {
    //     Swal.fire(delP.message, '', 'error')
    // }
  }

  const deleteRow = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Yes, delete it!',
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        removeTableRow(id);
      }
    })
  }

  const handlePaginationChange = (newPagination) => {
    setController({
      ...controller,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    });
  }

  function onSubmit(data) {
    const filtersData = getValues();
    setController({
      ...controller,
      filters: {
        ...controller.filters,
        searchKey: filtersData.searchKey,
        status: filtersData.status
      }
    });
  }

  const updateStatus = async (id, status) => {
    if (!id) return;
    const statusRes = await CustomerService.changeStatus({ id: id, status: status });
    if (statusRes.success) {  
        setDeleteRowData({id, status});
        Swal.fire('Status Change Successfully', '', 'success')
    } else {
        Swal.fire(statusRes.message, '', 'error')
    }
  }
  const changeStatus = (id, status) => {
    Swal.fire({
      title: 'Are you sure to change status?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Yes, Change Status!',
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        const newStatus = (status == 0) ? 1 : 0;
        updateStatus(id, newStatus);
      }
    })
  }
  const clearFilters = () => {
    reset();
    setController({
      ...controller,
      filters: {
        ...controller.filters,
        searchKey: "",
        status: ""
      }
    });
  }
  return (
    <Layout pageName={'Manage Customers'}>
      <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
        <Grid container>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} lg={3}>
                  <Controller
                    name="searchKey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Search here....."
                        label="Search"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={3}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="status-select-label">Select Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="status-select-label"
                          label="Select Status"
                        >
                          <MenuItem value="2">All</MenuItem>
                          <MenuItem value="1">Active</MenuItem>
                          <MenuItem value="0">Inactive</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} lg={3}>
                  <Tooltip title="Search">
                    <IconButton
                      color="primary"
                      type="submit"
                      sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear All Filters">
                    <IconButton
                      color="primary"
                      sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                      onClick={clearFilters}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '16px' }}>
            <Button 
              variant='contained' 
              onClick={() => navigate(`/admin/customerProfile/0`)} 
              sx={{ height: "36px" }} 
              endIcon={<AddCircleIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Card>

    <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff",borderRadius:'.5rem' }}>
          <DataGrid
            rows={list}
            columns={columns}
            getRowId={(row) => row.id}
            //checkboxSelection={true}
            rowCount={allRowCount}  // Use the length of the filtered list
            pageSizeOptions={[5, 10, 20, 50, 100]}
            paginationModel={controller}
            paginationMode="server"
            onPaginationModelChange={handlePaginationChange}

            loading={loading}

            disableColumnMenu
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: NoRowsLayout,
            }}

            rowHeight={50}
            autoHeight
            sx={{
              '--DataGrid-overlayHeight': '300px',
              "& .MuiDataGrid-cell": {
                border: 1,
                borderRight: 0,
                borderTop: 0,
                borderColor: 'lightgrey',
                p: 0,
                textAlign: 'center',
                justifyContent: 'center',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: '2px solid rgba(224, 224, 224, 1)',
                borderTop: '2px solid rgba(224, 224, 224, 1)',
              },
              '& .MuiDataGrid-columnHeader': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                '&:last-child': {
                  borderRight: 'none',
                },
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                color: '#000000'
              },
              '& .MuiDataGrid-row': {
                '&:last-child .MuiDataGrid-cell': {
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                },
                '& .MuiDataGrid-cell:last-child': {
                  borderRight: 'none',
                },
              },
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
            }}
          />
        </Box>
      </Box>
      {customerProfile && 
        <ViewProfile 
          customerDetails={customerProfile}
          onClose={()=>setCustomerProfile(null)}
        />
      }
    </Layout>
  )
}