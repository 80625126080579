import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { Box, Grid, Typography } from '@mui/material';
import { uploadImageS3Bucket } from '../../utils/s3bucketImg';
import { useForm, Controller } from 'react-hook-form';
// import { Services } from './Services';
import Swal from 'sweetalert2';
import { CategoryService } from '../../pages/Category/CategoryService';


const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

export default function ImgUploadDialog({ open, onClose, imgData, imgUploadResponse, trigStatus }) {
    const { control, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({
        mode: "onChange",
        defaultValues:{
            folder:imgData.path,
            file:'',
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState("");
    const [imgErr, setImgErr] = useState("");

    useEffect(() => {
        setImage("");
    }, [open])
    console.log(imgData)
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(URL.createObjectURL(file))
        setSelectedImage(file);
    };
    const onSelectedImage = (event, onChange) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            onChange(file);
        }
    };
    const handleUpload = async (data) => {
        setIsSubmitting(true);
        console.log("submitted data:",data)
        if (!data.file || !data.folder) {
            console.error("File or folder is missing");
            return;
        }
        
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('folder', data.folder);

        console.log("FormData contents:");
        for (let [key, value] of formData.entries()) {
            if (value instanceof File) {
                console.log(key, ":", {
                    name: value.name,
                    type: value.type,
                    size: value.size + " bytes"
                });
            } else {
                console.log(key, ":", value);
            }
        }
        try {
            const imageUploadResponse = await CategoryService.uploadImage(formData);
            console.log("Image upload response:", imageUploadResponse);
            // imgUploadResponse(result);
            if (imageUploadResponse.success=='1') {
                // const id = data.subCategory ? data.subCategory:data.category;
                // const imageUpdateData = {
                //     image:imageUploadResponse.data.imageName,
                // }
                imgUploadResponse(imageUploadResponse.data);
                // Swal.fire(imageUploadResponse.message,'','success');     
            } else {
                Swal.fire('Image upload Failed','','error');
                    onClose();
            }
            
        } catch (error) {
            console.error("Image Upload failed:", error);
        } finally {
            onClose();
            setIsSubmitting(true);
        }
    };
    return (
        <div>
            <Dialog
                // onClose={onClose}
                open={open}
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" >
                    Image Upload
                </DialogTitle>
                <Box component="form" onSubmit={handleSubmit(handleUpload)}>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers sx={{ width: '420px', height: '300px' }}>
                        <Grid container spacing={2}>
                            <Grid item lg={12}>
                            <Controller
                                control={control}
                                name="file"
                                rules={{
                                    required: 'Please select an image',
                                    validate: {
                                        fileType: (value) => {
                                            if (value) {
                                                const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
                                                return acceptedTypes.includes(value.type) || 'File type not supported. Please upload a PNG, JPEG, or JPG image.';
                                            }
                                            return true;
                                        },
                                        fileSize: (value) => {

                                            if (value) {
                                                return value.size <= MAX_FILE_SIZE || 'File size should be less than 5 MB.';
                                            }
                                            return true;
                                        }
                                    }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="file"
                                            onChange={(event) => {
                                                const file = event.target.files[0];
                                                onChange(file);
                                                onSelectedImage(event, onChange);
                                            }}
                                            style={{ display: 'none' }}

                                        />
                                        {/* <input
                                            id="upload-image"
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            onChange={handleImageChange}
                                        /> */}
                                        <Box
                                            component="label"
                                            htmlFor="file"
                                            // sx={{
                                            //     p: 3,
                                            //     display: 'flex',
                                            //     flexDirection: 'column',
                                            //     alignItems: 'center',
                                            //     justifyContent: 'center',
                                            //     border: '1px dashed',
                                            //     borderColor: 'grey.400',
                                            //     cursor: 'pointer',
                                            // }}
                                        >
                                            <Button sx={{ width: "100%" }} variant="outlined" component="span" disabled={isSubmitting}>
                                                Choose Product Images
                                            </Button>
                                        </Box>
                                        {error && (
                                            <Typography variant="caption" color="error">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                                />
                                {/* <label htmlFor="upload-image">
                                    <Button sx={{ width: "100%" }} variant="outlined" component="span">
                                        Choose Product Images
                                    </Button>

                                    <input
                                        id="upload-image"
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                </label> */}
                            </Grid>
                            {/* {imgErr && (
                                <Grid item lg={12}>
                                    <Typography variant='body' color="red">
                                        {imgErr}
                                    </Typography>
                                </Grid>
                            )} */}
                            <Grid item lg={12} >
                                {image && (
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 100,
                                            marginTop: "20px",
                                            marginLeft: "5px",
                                            borderRadius: "10px"
                                            // maxHeight: { xs: 233, md: 167 },
                                            // maxWidth: { xs: 350, md: 250 },
                                        }}
                                        alt="Payment Image"
                                        src={image}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        {/* <input type="file" accept="image/*" onChange={handleImageChange} /> */}


                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus type='submit' disabled={isSubmitting}>
                            Upload Image
                        </Button>
                        <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );
};


