import { useState, useEffect } from 'react';
import { Card, Typography, Divider, Stack, TextField, Grid, Button, Box, Toolbar, TableCell, FormControl, InputLabel, Select, Tooltip, IconButton, Modal } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import CustomNoRowsOverlay from '../../components/NoRows';
import DeleteIcon from '@mui/icons-material/Delete';
import AddService from './AddService';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


export default function ServiceList() {
    
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [populateTable, setPopulateTable] = useState(0);
    
    /************************  selected service/subservice data for edit**************************** */
    const [selectedServiceData,setSelectedServiceData] = useState({});

    /************************  Table Data State Manage **************************** */
   
    const [productList, setProductList] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [deleteProd, setDeleteProd] = useState(0);
    const [loading, setLoading] = useState(false);   

    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        filters: {
            searchKey: "",
            status: "1",
        }
    }); 
    
    const fetchServiceList = async()=>{
        // try {
        //     setLoading(true)
        //     const response = await Product.allProduct({
        //         page: controller.page,
        //         limit: controller.pageSize,
        //         filters: {
        //             searchKey: controller.filters.searchKey,   // Use the title filter from the controller
        //             status: controller.filters.status,
        //         }
        //     });
        //     setValue('status', controller.filters.status)
        //     if (response.success) {
        //         const data = response.data.list;
        //         setProductList(response.data.list);
        //         setProductCount(response.data.totalRows);
        //     } else {
        //         Swal.fire('Request failed','','error')
        //     }
        // } catch (error) {
        //     Swal.fire(error.message,'','error')
        // }
        // setLoading(false)
    }

    /************************  Manage Use Effect **************************** */
    // useEffect(() => {
    //     // fetchServiceList();
    // }, [controller, deleteProd, populateTable]);

  
    /************************  Table Column Manage **************************** */
    const columns = [
        { field: "id", headerName: "#ID", width: 50, editable: false },
        {
            field: "image", headerName: "Image", width: 80, editable: false, renderCell: (params) => (
                <TableCell>
                    {(params.row.product_master_images[0]) ? (
                        <img
                            src={`${params.row.product_master_images[0].image_url}sm/${params.row.product_master_images[0].image_title}`}
                            alt={params.row.product_master_images[0].image_title}
                            style={{ maxWidth: '50px', maxHeight: '50px' }}
                        />
                    ) : (
                        <ImageNotSupportedIcon />
                    )}

                </TableCell>
            ),
        },
        { field: "title", headerName: "Product Name", width: 250, editable: false },
        {
            field: "brand_name", headerName: "Brand", width: 100, editable: false,
            // valueGetter: (params) => {
            //     return params.row.product_brand.brand_name;
            // }
        },
        {
            field: "category_name", headerName: "Category", width: 180, editable: false,
            // valueGetter: (params) => {
            //     const category = (params.row.product_categories == undefined || params.row.product_categories == "") ? "N/A" : params.row.product_categories[0].category_name;
            //     return category;
            //     //return params.row.product_categories ? params.row.product_categories[0].category_name : "ddd";
            // }
        },

        { field: "sku", headerName: "SKU", width: 180, editable: false },
        {
            field: "createdAt", headerName: "Created Date", width: 180, valueGetter: (params) => {
                return formatDateTime(params.row.createdAt, "datetime")
            }
        },
        {
            field: "status", headerName: "Status", width: 100, 
            // renderCell: (params) => {
            //     return (
            //         <>
            //             {params.row.status ? (
            //                 <Button
            //                     variant="contained"
            //                     size='small'
            //                     sx={{ fontSize: "11px" }}
            //                     color="success"
            //                     onClick={(e) => changeStatus(params.row.id, params.row.status)}
            //                 >
            //                     Active
            //                 </Button>
            //             ) : (
            //                 <Button
            //                     variant="contained"
            //                     size='small'
            //                     sx={{ fontSize: "11px" }}
            //                     color="error"
            //                     onClick={(e) => changeStatus(params.row.id, params.row.status)}
            //                 >
            //                     InActive
            //                 </Button>
            //             )}
            //         </>
            //     )
            // }
        },
        {
            field: "actions", headerName: "Action", width: 180, 
            // renderCell: (params) => {


            //     return (
            //         <Stack spacing={2} direction="row">                        
            //             <Button
            //                 //variant='contained' 
            //                 onClick={(e) => handleAddEditProduct(params.row.id)}
            //                 sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
            //                 startIcon={<EditIcon />}
            //                 variant="outlined"
            //                 color="primary"
            //                 size='small'
            //             >
            //                 {isMobile ? "" : " Edit"}
            //             </Button>
            //             <DeleteIcon
            //                 style={{
            //                     fontSize: "20px",
            //                     color: "red",
            //                     cursor: "pointer",
            //                 }}
            //                 className="cursor-pointer"
            //                 onClick={(e) => deleteProduct(params.row.id)}
            //             />
            //         </Stack>
            //     );
            // }
        },
    ];

    /************************  Remove table row **************************** */
    const updateStatus = async (id, status) => {
        // if (!id) return;
        // const statusRes = await Product.changeStatus({ id: id, status: status });
        // if (statusRes.success) {
        //     setDeleteProd(id);
        //     Swal.fire('Status Change Successfully', '', 'success')
        // } else {
        //     Swal.fire(statusRes.message, '', 'error')
        // }
    }
    const changeStatus = (id, status) => {
        // Swal.fire({
        //     title: 'Are you sure to change status?',
        //     icon: 'warning',
        //     showDenyButton: true,
        //     confirmButtonText: 'Yes, Change Status!',
        //     denyButtonText: `No, cancel!`,
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         const newStatus = (status == 0) ? 1 : 0;
        //         updateStatus(id, newStatus);
        //     }
        // })
    }

    const removeProd = async (id) => {
        // if (!id) return;
        // const delP = await Product.deleteProduct(id);
        // if (delP.success) {
        //     setDeleteProd(id);
        //     Swal.fire('Deleted!', '', 'success')
        // } else {
        //     Swal.fire(delP.message, '', 'error')
        // }
    }

    const deleteProduct = (id) => {
        // Swal.fire({
        //     title: 'Are you sure?',
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showDenyButton: true,
        //     confirmButtonText: 'Yes, delete it!',
        //     denyButtonText: `No, cancel!`,
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         removeProd(id);
        //     }
        // })
    }
   
    const handlePaginationChange = (newPagination) => {
        setController({
            ...controller,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: filtersData.searchKey,
                status: filtersData.status
            }
        });
    }

    // const handleAddEditProduct = (id) => {
    //     window.open(`products/addEdit/${id}`, '_blank')
    // }
    const clearFilters = () => {
        reset();
        setController({
            ...controller,
            filters: {
                ...controller.filters,
                searchKey: "",
                status: ""
            }
        });
    }
    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [catType, setCatType] = useState(1);

    /************************  Modal Manage **************************** */
    const modalOpen = (rowData) => {
        setOpen(true);
        const requiredDataForEdit = {
            id:rowData.id,
            title: rowData.title,
            shortTitle: rowData.shortTitle,
            description: rowData.description ,
            image: rowData.images,
            serviceCategoryId: rowData.serviceCategoryId,
            parentServiceId: rowData.parentServiceId,
        }
        setSelectedServiceData(rowData);
    }
    // const modalVariationOpen = (catData) => {
    //     setOpenVaration(true);
    //     setCatData(catData);
    // }

    const handleClose = () => {
        // fetchServiceList();
        setOpen(false);

    //     setIds(0);
    //     setOpenVaration(false);
    //     setCatData([]);
    };
    const afterAddPop = (data) => {
        setPopulateTable(data);
    }

    return (
        <>
            {/* =========== pop up models like add/edit services and sub services=============== */}
            <Box>
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
                        <AddService 
                            closeEvent={handleClose} 
                            serviceData = {selectedServiceData}
                        />
                    </Box>
                </Modal>
            </Box>
            {/* ================= page title section and containing add service/sub service button============ */}
            <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        // sx={{ padding: '20px' }}
                    >
                        Manage Services
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button variant='contained' onClick={() => modalOpen({})} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                        Add Service
                    </Button>
                </Stack>
            </Card>

            {/* =================== Searching and sorting section ================== */}
            {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        label="Search"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={2}>
                        <Tooltip title="Search Button">
                                <IconButton
                                    color="primary"
                                    type="submit"
                                    sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear All Filters">
                                <IconButton
                                    color="primary"
                                    sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                                    onClick={clearFilters}
                                >
                                    <RestartAltIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card> */}

            {/* <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <DataGrid
                        rows={productList}
                        columns={columns}
                        getRowId={(row) => row.id}
                        //checkboxSelection={true}
                        rowCount={productCount}
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={controller}
                        paginationMode="server"
                        onPaginationModelChange={handlePaginationChange}
                        components={{ Toolbar: GridToolbar }}

                        loading={loading}
                        
                        disableColumnMenu
                        slots={{
                            noRowsOverlay: () => <CustomNoRowsOverlay />,
                        }}
                    />
                </Box>
            </Box> */}
        </>
    )
}