import axios from "axios";
import { LocalStore } from "../storage/authStore";
import { useLocation } from "react-router-dom";

export const commonRequest = async(methods, url, body, token=0) => {
    let header = {};
    // Check if the body is FormData
    const isFormData = body instanceof FormData;

    if (isFormData) {
        header = {
            "Content-Type": "multipart/form-data"
        };
    } else {
        header = {
            "Content-Type": "application/json"
        };
    }
    if(token){
        header.Authorization = `Bearer ${token}`;
    }
    let config = {
        method: methods,
        url,
        headers: header,
        data: body
    }
    
    // axios instance
    return axios(config).then((data) => {
        if(data.data.success===0){
            if(data.data.message==="Invalid Token"){
                LocalStore.doLogout();
            }
            if(data.data.message==="Token is expired"){
                LocalStore.doLogout();
            }
        }
        return data.data;
    }).catch((error)=>{
        if(error.response){
            if(error.response.data.message==="Invalid Token"){
                LocalStore.doLogout();
            }
            if(error.response.data.message==="Token is expired"){
                LocalStore.doLogout();
            }
            return error.response.data;
        }
        return error.message
    })
}