import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  Box,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  ListItemText,
  OutlinedInput,
  Autocomplete,
  Paper,
  Chip,
  Divider,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BookingResult from './BookingResult';
import { BookingService } from './Service';
import { CustomerService } from '../Customer/Service';
import ServiceArea from './ServiceAreaData.json'
import { BookingDetailContext } from './BookingInfo';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const chargeList = {
  couponId: '0',
  couponAmount: '0.00',
  discount: '0.00',
  platformCharges: '0.00',
}
export default function BookingDetails() {
  const {bookingDataInitialState} = useContext(BookingDetailContext);
  
  const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
    mode: "onChange",
    // defaultValues:{
    //   paymentMode:'pay after service',
    //   bookingStartTime: dayjs().add(1, 'hour').format('HH:mm'), // Set default time as one hour from now
    //   state:'Uttar Pradesh'
    // }
    defaultValues:bookingDataInitialState
  });
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (personalDetails) {
  //     setValue('firstName', personalDetails.firstName);
  //     setValue('middleName', personalDetails.middleName);
  //     setValue('lastName', personalDetails.lastName);
  //     setValue('email', personalDetails.email);
  //     setValue('dob', personalDetails.dob);
  //     setValue('gender', personalDetails.gender);
  //     setValue('mobileNumber', personalDetails.mobileNumber);
  //   }
  // }, [personalDetails]);

  

  const hours = Array.from({ length: 12 }, (_, i) => i.toString().padStart(2, '0'));
  const minutesSeconds = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  const validatebookingStartTime = (value) => {
    const serviceDate = watch('serviceDate');
    if (!serviceDate) return "Please select a booking date first";

    const selectedDate = dayjs(serviceDate, "DD-MM-YYYY");
    const selectedTime = dayjs(value, 'HH:mm');
    const now = dayjs();
    
    const startOfAllowedTime = dayjs().hour(9).minute(0);
    const endOfAllowedTime = dayjs().hour(21).minute(0);

    // Check if booking is for today
    if (selectedDate.isSame(now, 'day')) {
      const oneHourLater = now.add(1, 'hour');
      if (selectedTime.isBefore(oneHourLater)) {
        return 'Time must be at least one hour from now for today.';
      }
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM.';
      }
    } else {
      // For future dates
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM for future days.';
      }
    }

    return true;
  };

  const [customerList,setCustomerList] = useState([]);
  const [categoryList,setCategoryList] = useState([]);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [servicablePincodeList,setServicablePincodeList] = useState([]);
  const [serviceList,setServiceList] = useState([]);
  const [serviceExpertList,setServiceExpertList]=useState([]);
  const [timeSlotList,setTimeSlotList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  
  const customer= watch('customer');
  const categoryId= watch('categoryId');
  const subCategoryId=watch('subCategoryId');
  const serviceArea = watch('serviceArea');
  const serviceExpert = watch('serviceExpert');
  const services = watch('services');
  const selectedDate= watch('serviceDate');

  useEffect(()=>{
    const fetchCusomerlist = async()=>{
      try {
        const customerListResponse = await CustomerService.unsortedCustomerList();
        console.log("customer list Response:",customerListResponse);
        if(customerListResponse.success===1){
          setCustomerList(customerListResponse.data.customers)
        }
      } catch (error) {
        
      }
    }
    const fetchCategoryList= async()=>{
      try {
        const categoryListResponse = await BookingService.categoryList();
        console.log("category list Response:",categoryListResponse);
        if(categoryListResponse.success===1){
          setCategoryList(categoryListResponse.data)
        }
      } catch (error) {
        
      }
    }
    const fetchServicablePincodes= async()=>{
      try {
        const servicablePincodeListResponse = await BookingService.servicablePincodeList();
        console.log("servicable pincodes list Response:",servicablePincodeListResponse);
        if(servicablePincodeListResponse.success===1){
          setServicablePincodeList(servicablePincodeListResponse.data)
        }
      } catch (error) {
        
      }
    }
    
    fetchCusomerlist();
    fetchCategoryList();
    fetchServicablePincodes();
  },[])
  
  useEffect(()=>{
    setValue('subCategoryId','')
    if(categoryId){  
      const fetchSubCategoryList= async()=>{
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
          console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchSubCategoryList();
    }
  },[categoryId])

  useEffect(()=>{
    // Function to reset services
    setValue('serviceExpert', '');
  },[subCategoryId])

  useEffect(()=>{
    setValue('serviceExpert','')
    if(serviceArea){
      
      const fetchServiceExpertList= async()=>{
        try {
          const serviceExpertListResponse = await BookingService.serviceExpertList({pincode:serviceArea.pincode});
          console.log("service expert list Response:",serviceExpertListResponse);
          if(serviceExpertListResponse.success===1){
            setServiceExpertList(serviceExpertListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchServiceExpertList();
    }
  },[serviceArea])
  
  useEffect(()=>{
    // Function to reset services
    const resetServices = () => {
      // setValue('serviceExpert', '');
      setValue('services', []);
      setSelectedServices([]);
    };

    // Reset services when expert
    resetServices();
    if(subCategoryId && serviceExpert){
      console.log(serviceExpert)
      const fetchServiceList= async()=>{
        try {
          const serviceListResponse = await BookingService.serviceList({subCategoryId:subCategoryId,providerId:serviceExpert});
          console.log("service list Response:",serviceListResponse);
          if(serviceListResponse.success===1){
            setServiceList(serviceListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchServiceList();
    }
  },[serviceExpert])
  
  // useEffect(() => {
    
  // }, [services]);

  const handleQuantityChange = (id, quantity) => {
    const items = services.map(service => 
      service.id === id ? { ...service, quantity: parseInt(quantity) || 0,serviceTime:(service.serviceTime/service.quantity)* parseInt(quantity) || 0} : service
    );
    setValue('services',items);
  };
  const handleDeleteService = (id) => {
    const updatedServices = services.filter(service => service.id !== id);
    // setSelectedServices(updatedServices);
    setValue('services', updatedServices); // Update the form value
  };

  
  useEffect(()=>{
    setValue('timeSlot','');
    setTimeSlotList([])
    let totalServiceTime = 0;
    if (services.length>0) {
      // setSelectedServices(services.map(service => ({ ...service, quantity: 1 })));
      services.map((service)=>totalServiceTime=totalServiceTime+service.serviceTime);
    }else{
      setValue('serviceDate','')
      setValue('timeSlot','')
    }
    if (selectedDate && services.length>0) {
      console.log("selected Date:",selectedDate)
        const fetchTimeSlots= async()=>{
          const data={    
            customerId: watch('customer')?.id,
            providerId: serviceExpert,
            serviceTimeTotal: totalServiceTime,
            timeSlotDate: selectedDate
          }
          console.log("required data for getting time slot:",data);
          try {
            const timeSlotResponse = await BookingService.timeSlots(data);
            console.log("Time slot Response:",timeSlotResponse);
            if(timeSlotResponse.success=="1"){
              setTimeSlotList(timeSlotResponse.data)
            }else{
              Swal.fire(timeSlotResponse.message,'','error');
            }
          } catch (error) {

          }
        }
        fetchTimeSlots();
    }
  },[selectedDate,services])
  /*..........related to customer list autocomplete................ */

  const getFullName = (profile) => {
    if (!profile) return '';
    return [profile.firstName, profile.middleName, profile.lastName]
      .filter(Boolean)
      .join(' ');
  };
  /*------------- submitting form and handling response related----------*/ 
  const [bookingResponseDetails,setBookingResponseDetails] = useState({
    status:'',
    message:'',
    data:''
  })
 
  const [isSubmitting,setIsSubmitting] = useState(false);
  const onSubmit = async(data) => {
    setIsSubmitting(true);
    console.log("submitted booking form data:",data);
    let subTotal =0;
    const items = data.services.map((service)=>{
      subTotal = parseFloat(subTotal)+parseFloat(service.price * service.quantity);
      return {
        providerServiceId: service.id,
        // serviceName: service.title,
        // price: service.price * service.quantity,
        quantity:service.quantity,
      }
    });
    
    const formattedDatatobeSend = {
      customerId: data.customer.id,
      providerId: data.serviceExpert,
      paymentId: 1,
      serviceDate: data.serviceDate,
      bookingStartTime:data.timeSlot.startTime,
      bookingEndTime:data.timeSlot.endTime,
      subTotal: parseFloat(subTotal).toFixed(2),
      couponId: chargeList.couponId,
      couponAmount: chargeList.couponAmount,
      discount: chargeList.discount,
      platformCharges: chargeList.platformCharges,
      total: parseFloat(subTotal).toFixed(2),  
      callbackRequest: true,
      add_customerFullName: `${data.customer.profile.firstName} ${data.customer.profile.middleName} ${data.customer.profile.lastName}`,
      add_mobileNumber: data.customer.mobileNumber,
      add_pincode: data.serviceArea.pincode,
      add_latitude: '',
      add_longitude: '',
      add_city: data.serviceArea.city,
      add_state: data.serviceArea.state,
      add_landmark: data.landmark,
      add_address:data.address ,
      items: items,
    }
   console.log("data to be send for booking:",formattedDatatobeSend);
    try {
        const bookingResponse = await BookingService.addBooking(formattedDatatobeSend);
        console.log("new booking Response:",bookingResponse);
        if(bookingResponse.success=='1'){
          setBookingResponseDetails({status:'true',message:'booking successfull',data:bookingResponse.data})
          setIsSubmitting(true);
        }else{
          setBookingResponseDetails({status:'false',message:'booking failed',data:{}})
          setIsSubmitting(true);
        }
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" ,padding:{xs:'2%',md:'2% 0%'} }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} >
          <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
            {/* first container....customer selection */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Customer :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name="customer"
                          control={control}
                          rules={{
                            required: 'Select customer',
                            validate: (value) => (value && Object.keys(value).length > 0) || 'Select customer'
                          }}
                          // defaultValue={null}  // Set a default value
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              options={customerList || []}  // Provide a fallback empty array
                              getOptionLabel={(option) => {
                                if (!option) return '';  // Handle null/undefined option
                                const profile = option.profile || {};  // Use empty object as fallback
                                const fullName = getFullName(profile);
                                return fullName ? `${fullName} (${option.mobileNumber || ''})` : '';
                              }}
                              value={value}
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  variant="outlined"
                                  error={!!error}
                                  // helperText={error?.message}
                                />
                              )}
                              isOptionEqualToValue={(option, value) => {
                                if (!option || !value) return false;  // Handle null/undefined values
                                return option.id === value.id;
                              }}
                              noOptionsText="No customers found" // Custom text when no options match
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/* second container */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Category :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='categoryId'
                          control={control}
                          rules={{required:'select category'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                              {/* <InputLabel id="">Select Gender</InputLabel> */}
                                <Select
                                    {...field}
                                    disabled={!customer}
                                    // label="Select Gender"
                                    // sx={{height:'2rem'}}
                                    error={!!error}
                                    // helperText={error && error?.message}
                                >
                                  { categoryList &&
                                    categoryList.map((category,index)=>(
                                      <MenuItem value={category.id} key={index}>{category.title}</MenuItem>
                                    ))

                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Sub-Category :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='subCategoryId'
                          control={control}
                          rules={{required:'select sub-category'}}
                          render={({field,fieldState:{error}})=>(
                            <FormControl size='small' sx={{ minWidth: "100%" }}>
                              {/* <InputLabel id="">Select Gender</InputLabel> */}
                                <Select
                                    {...field}
                                    disabled={!customer || !categoryId}
                                    // label="Select Gender"
                                    // sx={{height:'2rem'}}
                                    error={!!error}
                                    // helperText={error && error?.message}
                                >
                                  {subCategoryList &&
                                    subCategoryList.map((subCategory,scIndex)=>(
                                      <MenuItem value={subCategory.id} key={scIndex}>{subCategory.title}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid> 
                </Grid>
              </Grid>
              {/* ............right section ends................... */}
            </Grid>
            
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/*  third container....pincode ,provider and service */}
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12} lg={5}>
                <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'700'}}>Enter Pincode :</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Controller
                    name='serviceArea'
                    control={control}
                    rules={{
                      required: "Pincode selection is required"
                    }}
                    render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        options={servicablePincodeList}
                        disabled={!customer || !subCategoryId}
                        value={value || null}
                        getOptionLabel={(option) => 
                          option ? `${option.pincode} - ${option.city}, ${option.state}` : ''
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size='small'
                            error={!!error}
                            // helperText={error && error.message}
                            placeholder="Select a pincode"
                          />
                        )}
                        onChange={(_, newValue) => onChange(newValue)}
                        isOptionEqualToValue={(option, value) => 
                          option && value ? option.pincode === value.pincode : option === value
                        }
                        disableClearable
                        noOptionsText="Service not avaialble" // Custom text when no options match
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} lg={2}></Grid>
              <Grid item xs={12} lg={5}>
                <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'700'}}>Select Service Expert:</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Controller
                  name='serviceExpert'
                  control={control}
                  rules={{required:'Select service Expert'}}
                  render={({field,fieldState:{error}}) => (
                    <FormControl fullWidth size='small' error={!!error}>
                      <Select {...field} 
                        disabled={!customer || !serviceArea}
                      >
                        {serviceExpertList.map((expert) => (
                          <MenuItem key={expert.id} value={expert.id}>{expert.providerName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            <Grid container spacing={2} mb={1}>
              <Grid item xs={12} lg={5}>
              <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'700'}}>Select Services:</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Controller
                  name='services'
                  control={control}
                  rules={{ required: 'Select service' }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      disabled={!customer || !serviceExpert}
                      disableCloseOnSelect
                      options={serviceList}
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.title}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          error={!!error}
                          variant="outlined"
                          placeholder="Select services"
                        />
                      )}
                      onChange={(_, newValue) => {
                        const servicesWithQuantity = newValue.map(service => ({
                          ...service,
                          quantity: service.quantity || 1
                        }));
                        field.onChange(servicesWithQuantity);
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderTags={() => null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{display:services.length>0?'block':'none'}}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{fontWeight:'bold'}} width="40%" >Service</TableCell>
                        <TableCell sx={{fontWeight:'bold'}} align="center" width="15%">Rate</TableCell>
                        <TableCell sx={{fontWeight:'bold'}} align="center" width="15%">Quantity</TableCell>
                        <TableCell sx={{fontWeight:'bold'}} align="center" width="15%">Total Cost</TableCell>
                        <TableCell sx={{fontWeight:'bold'}} align="right" width="15%">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {services.map((service) => (
                        <TableRow key={service.id}>
                          <TableCell sx={{ p: 1 }}>{service.title}</TableCell>
                          <TableCell sx={{ p: 1 ,textAlign:'center'}}>{service.price}</TableCell>
                          <TableCell align="center" sx={{ p: 1 }}>
                            <TextField
                              type="number"
                              value={service.quantity}
                              onChange={(e) => handleQuantityChange(service.id, e.target.value)}
                              inputProps={{ 
                                min: 1, 
                                className: 'quantity-input',
                                style: { textAlign: 'center', width: '3em' } 
                              }}
                              size="small"
                              variant="standard"
                            />
                          </TableCell>
                          <TableCell sx={{ p: 1 ,textAlign:'center'}} >{parseFloat(service.price*service.quantity).toFixed(2)}</TableCell>
                          <TableCell align="right" sx={{ p: 1 }}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteService(service.id)}
                              size="small"
                              color='error'
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>  
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            {/* fourth container.....date time selction section  */}

            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Date :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name="serviceDate"
                          control={control}
                          rules={{
                            validate: (value) => {
                              if (!value) return "This field is required";
                              const date = dayjs(value);
                              const today = dayjs().startOf('day');
                              return date.isValid() && (date.isSame(today, 'day') || date.isAfter(today)) || "Booking Date must be today or in the future";
                            }
                          }}
                          render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                {...restField}
                                disabled={!watch('services').length>0 || !watch('customer')}
                                value={value ? dayjs(value) : null}
                                onChange={(newValue) => {
                                  // Store the date in YYYY-MM-DD format for database compatibility
                                  onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                                }}
                                format="DD-MM-YYYY" // Display format for user
                                minDate={dayjs()}
                                slotProps={{
                                  textField: {
                                    error: !!error,
                                    helperText: error?.message,
                                    fullWidth: true,
                                    required: true,
                                    size: 'small'
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}>
                
              </Grid>
              {/* ............right section................... */}
              {/* <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Select Time :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                      <Controller
                          name="bookingStartTime"
                          control={control}
                          rules={{
                            required: 'Required',
                            validate: validatebookingStartTime, // Apply time validation
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              size="small"
                              id="time"
                              type="time"
                              disabled={!watch('serviceDate')|| !watch('customer')}
                              inputProps={{
                                step: 300, // 5 minutes step
                              }}
                              // sx={{ width: 150 }}
                              error={!!error}
                              helperText={error ? error.message : ''}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                      <Grid container sx={{mb:'1%'}}>
                          <Grid item lg={7} xs={12}>
                              <Typography sx={{fontWeight:'700'}}>Select Time Slot:</Typography>
                          </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item lg={12} xs={12}>
                          <Controller
                              name="timeSlot"
                              control={control}
                              rules={{
                                  required: 'Please select a time slot',
                              }}
                              render={({ field, fieldState: { error } }) => (
                                  <FormControl component="fieldset" error={!!error} fullWidth>
                                      <RadioGroup 
                                        {...field}
                                        value={field.value ? JSON.stringify(field.value) : ''}
                                        onChange={(event) => field.onChange(JSON.parse(event.target.value))}
                                        sx={{
                                            display: services.length>0?'flex':'none',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                        }}
                                      >
                                          {timeSlotList.map((slot, index) => (
                                            <Box key={index} sx={{ margin: '4px',width:'fit-content' }}>
                                              <FormControlLabel
                                                  value={JSON.stringify(slot)}
                                                  control={<Radio size="small" />}
                                                  label={
                                                      <Typography variant="body2">
                                                          {slot.startTime.slice(0, -3)} - {slot.endTime.slice(0, -3)}
                                                      </Typography>
                                                  }
                                                  disabled={!watch('serviceDate') || !watch('customer')}
                                                  sx={{ 
                                                      border: '1px solid #e0e0e0',
                                                      borderRadius: '4px',
                                                      padding: '4px 8px',
                                                      '&:hover': {
                                                          backgroundColor: '#f5f5f5',
                                                      },
                                                  }}
                                              />
                                            </Box>
                                          ))}
                                      </RadioGroup>
                                      {error && <FormHelperText>{error.message}</FormHelperText>}
                                  </FormControl>
                              )}
                          />
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
            </Grid>
              {/* ............right section ends................... */}
            </Grid>
            
            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>

            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Enter Address :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name='address'
                          control={control}
                          rules={{required:'Enter Address'}}
                          render={({field,fieldState:{error}})=>(
                            <TextField
                              {...field}
                              size='small'
                              fullWidth
                              multiline
                              error={!!error}
                            />
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
              {/* ............central gap................... */}
              <Grid item xs={'none'} lg={2}></Grid>
              {/* ............right section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>landmark (optional) :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                      <Controller
                          name='landmark'
                          control={control}
                          // rules={{required:'Enter Address'}}
                          render={({field,fieldState:{error}})=>(
                            <TextField
                              {...field}
                              size='small'
                              fullWidth
                              error={!!error}
                            />
                          )}  
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider sx={{my:'1%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            
            {/* sixth container */}
            <Grid container spacing={2} >
              {/* ............left section................... */}
              <Grid item xs={12} lg={5}>
                <Grid container>
                  <Grid item xs={12} sx={{my:'1%'}}>
                    <Grid container sx={{mb:'1%'}}>
                      <Grid item lg={7} xs={12}>
                        <Typography sx={{fontWeight:'700'}}>Payment Method :</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item lg={12} xs={12}>
                        <Controller
                          name="paymentMode"
                          control={control}
                          // rules={{ required: "Please select paymentMode" }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl sx={{ minWidth: "100%" }} component="fieldset" error={!!error}>
                              {/* Replace Select with Checkboxes */}
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      // checked={watch('paymentMode')}
                                      checked={watch('paymentMode')=='pay after service'}
                                      onChange={(e) => field.onChange('pay after service')}
                                    />
                                  }
                                  label="Pay After Service"
                                />
                                
                              </FormGroup>
                              {/* {error && <FormHelperText>{error.message}</FormHelperText>} */}
                            </FormControl>
                          )}
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* submit button section */}
            <Grid container>    
              <Grid item xs={12} sx={{my:'2%'}}>
                <Box textAlign={'center'}>
                  <Button disabled={!isDirty || isSubmitting} type="submit" variant="contained" color="primary">
                    confirm Booking
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {bookingResponseDetails.message && 
        <BookingResult 
          bookingResponseDetails={bookingResponseDetails}
          onCloseDialog={()=>{
            reset();
            setIsSubmitting(false);
            setBookingResponseDetails({status:'',message:'',data:''})
          }}

        />
      }
    </Box >
  );
}
