import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const BookingService = {
    bookingList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-booking/getBookingList/0/${data.filters.status}/${data.page+1}/${data.limit}`,{}, Helpers.token());
    },

    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-category/getCategories`,{}, Helpers.token());
    },

    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    servicablePincodeList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/provider-services/admin/ServingPincodes/getAllServingPincodes`,{}, Helpers.token());
    },

    serviceExpertList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/provider-services/admin/getProviders/${data.pincode}`,{}, Helpers.token());
    },

    serviceList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/provider-services/admin/getServices/${data.providerId}/${data.subCategoryId}`,{}, Helpers.token());
    },

    timeSlots : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/public/booking/getProviderTimeSlots`,data, Helpers.token());
    },

    bookingDetailById : async (data) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/service-booking/getBookingDetails/${data.bookingId}`, {}, Helpers.token());
    },

    addBooking : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/service-booking`,data, Helpers.token());
    },

    updateBooking : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/customer/${id}`, data, Helpers.token());
    },

    changeStatus: async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/customer/statusChange`,data, Helpers.token());
    },

    // addBankDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/bankDetail/create`,data, Helpers.token());
    // },

    // updateBankDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/bankDetail/${id}`, data, Helpers.token());
    // },

    // addAddressDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/addressDetail/create`,data, Helpers.token());
    // },

    // updateAddressDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/addressDetail/${id}`, data, Helpers.token());
    // },

    // deleteBrand : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    // },
    
}