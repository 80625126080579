import Layout from '../../components/Layout/Layout';
import { Box, Button, Card, Grid, IconButton, LinearProgress, Stack, Switch, Typography,Tooltip,MenuItem, TextField, FormControl, InputLabel, Select } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import TableLoadingSkeleton from '../../components/Layout/TableLoadingSkeleton';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { BookingService } from './Service';
import Swal from 'sweetalert2';
import { Edit } from '@mui/icons-material';
import ViewDetails from './ViewDetails';
import { render } from '@testing-library/react';
import { formatDateTime } from '../../utils/dateUtils';

const BookingList = () => {
  const navigate = useNavigate();

  /* ....................booking table related..................... */
  const[isLoading,setIsLoading] = useState(true);
  const [selectedBookingDetails,setSelectedBookingDetails] = useState(null);
  const columns = [
    { field: 'id', headerAlign: 'center',headerName: 'S.NO', width: 60,
        renderCell: (params) => {
            // const rowIndex = params.api.getAllRowIds().indexOf(params.id)+1;
            return (
                <strong className='font-bold text-center self-center'>{params.row.id}</strong>
            );
        }
    },
    { field: 'serviceDate', headerAlign: 'center',headerName: 'Service Date', flex:1,
      renderCell:(params)=>{
        return(
          <>{formatDateTime(params.row.serviceDate, 'date')}</>
        )
      }
    },
    { field: 'timeSlot', headerAlign: 'center',headerName: 'Time Slot', flex:1,
      renderCell:(params)=>{
        return(
          <>{params.row.bookingStartTime} - {params.row.bookingEndTime}</>
        )
      }
    },
    { field: 'bookingStatus', headerAlign: 'center',headerName: 'Status',flex:1,},
    { field: 'customer', headerAlign: 'center',headerName: 'Customer contact', flex:1,
      renderCell: (params) => {
        // const rowIndex = params.api.getAllRowIds().indexOf(params.id)+1;
        return (
            <>{params.row.customer.mobileNumber.replace(/\D/g, '')}</>
        );
      }
    },
    // { field: 'serviceName', headerAlign: 'center',headerName: 'service', width:200,
    //   renderCell: (params) => {
    //     // const rowIndex = params.api.getAllRowIds().indexOf(params.id)+1;
    //     return (
          
    //         params.row.bookingItems.map((item,index)=>(
    //         <Box key={index}>{item.serviceName}{params.row.bookingItems.length>1 && index<params.row.bookingItems.length-1 && ','}</Box>
    //         ))
                    
    //     );
    //   }
    // },
    { field: 'provider', headerAlign: 'center',headerName: 'Provider', flex:1,
      renderCell:(params)=>{        
        const provider = params.row.provider;
        return(
          <Box component={'span'} sx={{textTransform:'uppercase'}}>{(provider)? provider.UserProfile.firstName: ''}{' '}{(provider)? provider.UserProfile.lastName: ''}</Box>
        )
      }
    },
    // { field: 'add_address', headerAlign: 'center',headerName: 'Address', width:200},
    { field: 'bookingNumber', headerAlign: 'center',headerName: 'bookingNumber', flex:1},
    {field: 'action', headerAlign: 'center',headerName: 'Action', width: 120, sortable: false, 
      renderCell: (params) => {
        const currentRow = params.row;
          const viewImage = (e) => {
            // const currentRow = params.row;
            // setImagePath(params.row.filePath);
            // setOpenImageDialog(true);
          };
          const onClickDelete = async(e) => {
            // const currentRow = params.row;
            // const response = await api.get(`/rest-api/photo-gallery/crud?action=delete&id=${currentRow._id}`);
            // console.log(response);  
            // if(response.data.status){
            //   fetchRowCount();  
            //   fetchRows();
            //   }
          };
          return (
            <Box className='flex items-center justify-center h-full'>
              <IconButton color='warning' onClick={()=>setSelectedBookingDetails(currentRow)}><RemoveRedEyeIcon/></IconButton>
              {/* <IconButton color='warning' 
                // onClick={()=>navigate(`/admin/booking/${currentRow.id}`)}
              ><Edit/></IconButton>
              <IconButton color='error' onClick={onClickDelete}><DeleteForeverIcon/></IconButton> */}
            </Box>
          );
      },
    },
    // { field: 'status',headerAlign: 'center',headerName: 'status', width: 90,sortable: false,
    //     // disableClickEventBubbling: true,  
    //     renderCell: (params) => {
    //         const onClick = async(e) => {
    //         const currentRow = params.row;
    //         // console.log("currentRowId:",currentRow._id);
    //         // const response = await api.get(`/rest-api/photo-gallery/crud?action=modify-status&id=${currentRow._id}`);
    //         // console.log(response);  
    //         // if(response.data.status){
    //         //   fetchRowCount();  
    //         //   fetchRows();
    //         //   }
    //       };
    //         return (
              
    //             <Switch checked={params.row.status} color='success' onChange={onClick}/>
    //         );
    //     },
    // },
    // { field: 'categoryName', headerAlign: 'center',headerName: 'category', },
    
    // { field: 'uploadedBy', headerAlign: 'center',headerName: 'Uploaded By',flex:1 },
  ];
   // State to manage pagination settings
   const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
    filters: {
      searchKey: "",
      status: 'requested',
    }
  });

  const handlePaginationModel = (newPagination) => {
    setPaginationModel({
      ...paginationModel,
      page: newPagination.page,
      pageSize: newPagination.pageSize
    });
  }

  // State to store the total row count from the server
  const [rowCount, setRowCount] = useState(5); 
    
  // State to store the row count used by the DataGrid
  const [rowCountState, setRowCountState] = useState(rowCount);
    
  // Update rowCountState whenever rowCount changes
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState,
    );
  }, [rowCount, setRowCountState]);


  // State to store the rows fetched from the server
  const [rowData, setRowData] = useState([]);
  // Effect to fetch row count and rows when paginationModel changes
  useEffect(()=>{
    const getData = async () => {
      try {
        
        setIsLoading(true)
        const listResponse = await BookingService.bookingList({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: {
            searchKey: paginationModel.filters.searchKey,   // Use the title filter from the paginationModel
            status: paginationModel.filters.status,
          }
        });
        console.log("booking list Response:",listResponse);
        setValue('status', paginationModel.filters.status)
        if (listResponse.status === "success") {
          setRowData(listResponse.data.rows);
          setRowCount(listResponse.data.totalRows);
        } 
        // else {
        //   Swal.fire('Request failed','','error')
        // }
      } catch (error) {
        console.log(error)
        Swal.fire(error.message,'','error')
      }
      setIsLoading(false)
    };
    getData();
  },[paginationModel])
//   }, [paginationModel, sortModel, filterModel]);

  /*............search realted.................... */

  const {control,handleSubmit,reset, setValue, getValues, formState: { errors }} = useForm();
  function onSubmit(data) {
    // setIsLoading(true);
    const filtersData = getValues();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: filtersData.searchKey,
        status: filtersData.status
      }
    });
  }
  const clearFilters = () => {
    reset();
    setPaginationModel({
      ...paginationModel,
      filters: {
        ...paginationModel.filters,
        searchKey: "",
        status: "requested"
      }
    });
  }
 
  return (
    <Layout pageName={'Manage Bookings'}>
      <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
        <Grid container>
          <Grid item xs={9}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} sx={{ p: 2 }}>
                {/* <Grid item xs={12} lg={3}>
                  <Controller
                    name="searchKey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Search here....."
                        label="Search"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12} lg={3}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="status-select-label">Select Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="status-select-label"
                          label="Select Status"
                        >
                          {/* <MenuItem value="all">All</MenuItem> */}
                          <MenuItem value='initiated'>initiated</MenuItem>
                          <MenuItem value='inprogress'>inprogress</MenuItem>
                          <MenuItem value='requested'>requested</MenuItem>
                          <MenuItem value='accepted'>accepted</MenuItem>
                          <MenuItem value='rejected'>rejected</MenuItem>
                          <MenuItem value='completed'>completed</MenuItem>
                          <MenuItem value='ongoing'>ongoing</MenuItem>
                          <MenuItem value='cancelled'>cancelled</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Tooltip title="Search">
                    <IconButton
                      color="primary"
                      type="submit"
                      sx={{ border: "1px solid", borderRadius: "7px", padding: "6px 6px" }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear All Filters">
                    <IconButton
                      color="primary"
                      sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "6px 6px" }}
                      onClick={clearFilters}
                    >
                      <RestartAltIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '16px' }}>
            <Button 
              variant='contained' 
              onClick={() => navigate(`/admin/booking/0`)} 
              sx={{ height: "36px" }} 
              endIcon={<AddCircleIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card>
        {/* <Box className="bg-[#009688] text-center dark:bg-boxdark">
          <Typography variant='p' className="font-bold text-xl text-white ">Image Gallery List</Typography>
        </Box> */}
        <Box sx={{ overflow: "auto", backgroundColor: "#fff" }}>
         <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <DataGrid
              columns={columns} // Column definitions
              rows={rowData} // Data to be displayed in the grid
              // getRowId={(row) => row._id} // Custom row ID function
              pageSizeOptions={[5, 10, 25]} // Options for the page size dropdown
              rowCount={rowCountState} // Total number of rows
              paginationModel={paginationModel} // Current pagination settings
              /**==below code is wriiten if pagination model not defined by user */
              // initialState={{
                
              //   pagination: { paginationModel: { pageSize: 5 } },
              // }}
              disableRowSelectionOnClick

              //*======server side Sorting section */
              // sortingMode="server"
              // onSortModelChange={setSortModel}
              //*======server side Sorting section */

              //*======server side filter section */
              // filterMode="server"
              // onFilterModelChange={setFilterModel}
              //*======server side filter section */

              //*======server side pagination section */
              paginationMode="server" // Enable server-side pagination
              onPaginationModelChange={handlePaginationModel} //this is called when no.of rows per page chnged or pagenumber change
              //*======server side pagination section */
              disableColumnMenu
              // components={{ Toolbar: GridToolbar }}
              slots={{
                toolbar: GridToolbar,
                // loadingOverlay: TableLoadingSkeleton,
                noRowsOverlay: NoRowsLayout,
              }}
              loading = {isLoading}

              rowHeight={50}
              autoHeight
              sx={{
                '--DataGrid-overlayHeight': '300px',
                "& .MuiDataGrid-cell": {
                    border: 1,
                    borderRight: 0,
                    borderTop: 0,
                    borderColor:'lightgrey',
                    // add more css for customization
                    p:0,
                    textAlign:'center',
                    justifyContent:'center',
                    },
                // '& .super-app-theme--header': {
                //   backgroundColor: 'rgba(255, 7, 0, 0.55)',
                // },
                // '& .MuiDataGrid-columnHeader': { backgroundColor: "#000000",border:'1px solid lightgray'},
                '& .MuiDataGrid-columnHeaders': {
                borderBottom: '2px solid rgba(224, 224, 224, 1)',
                borderTop: '2px solid rgba(224, 224, 224, 1)',
                // borderRight: '1px solid rgba(224, 224, 224, 1)',
              },
                '& .MuiDataGrid-columnHeader': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                '&:last-child': {
                  // borderRight: 'none',
                },
              },
                '& .MuiDataGrid-columnHeaderTitle': {fontWeight: 'bold',color:'#000000' },
                '& .MuiDataGrid-row': {
                '&:last-child .MuiDataGrid-cell': {
                  // borderBottom: 'none',
                },
              },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Box>
        </Box>
      </Card>
      {selectedBookingDetails && <ViewDetails selectedBookingDetails={selectedBookingDetails} setSelectedBookingDetails={setSelectedBookingDetails}/>}
    </Layout>
  );
}

export default BookingList;
